<template>
  <b-card
    :img-src="
      cartItem && cartItem.images
        ? cartItem.images.smallImage
        : cartItem.imageLink
    "
    img-alt="Card image"
    img-left
    class="mb-3"
  >
    <b-card-text class="card-text-main">
      {{
        cartItem &&
        cartItem.description_summary &&
        cartItem.description_summary.length > 20
          ? cartItem && cartItem.Name
          : cartItem && cartItem.description_summary
      }}
      {{ cartItem ? cartItem.Quantity : cartItem.qty }}
    </b-card-text>
    <b-card-text class="card-text-sub">
      {{ cartItem ? cartItem.Brand : "" }}
    </b-card-text>
    <b-row>
      <b-col>
        <div>
          <b-card-text class="card-text-price"
            >₹ {{ cartItem && cartItem.Price }}
          </b-card-text>
        </div>
        <b-card-text class="mt-1"
          ><span class="order-provider-tag">Provider : </span>
          <span class="order-provider-name"> {{ cartItem.providerName }} </span>
          <span class="float-right"> </span>
        </b-card-text>
      </b-col>
      <template v-if="$options.components['checkout-increment-decrement']">
        <checkout-increment-decrement
          :callCheckout="true"
          :cartItem="cartItem"
        />
      </template>
    </b-row>
  </b-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "cart-item",
  props: {
    cartItem: {
      default: () => {},
      type: Object,
    },
  },
  computed: {
    ...mapState({
      cartData: (state) => state.checkout.cartData,
    }),
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.card-img-left {
  border-top-left-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
  margin: 5px;
  height: 120px;
  width: 120px;
  object-fit: contain;
}

.card-text-main {
  font-size: 18px;
  line-height: 20px;
}
.card-text-sub {
  font-size: 16px;
  line-height: 10px;
  color: #a4a4a4;
  margin-left: 2px;
}
.card-text-price {
  font-size: 26px;
  line-height: 30px;
}
.order-provider-tag {
  font-size: 14px;
}
.order-provider-name {
  font-size: 14px;
  text-decoration-line: underline;
  color: #3977d3;
}
.card {
  border: none;
  font-weight: 500;
  font-style: normal;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-bottom-color: rgba(0, 0, 0, 0.125);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-image: initial initial initial;
}

@media (max-width: 768px) {
  .inc-dec-mobile-view {
    margin-bottom: 15px;
  }
}
</style>
